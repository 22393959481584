import React from 'react'
import tw from 'twin.macro'
import moment from 'moment'

import Layout from '../components/Layout'
import { H1, Text } from '../components/core/typography'
import { graphql } from 'gatsby'
import { HTMLContent } from '../components/Content'
import Image from 'gatsby-image'
import { Container } from '../components/core/container'
import SEO from '../components/SEO'

const ContentWrapper = tw(Container)`
  my-24
  last:(mb-0)
  max-w-screen-lg
`

const LargeEventCard = ({ image, location, startDate, facebookEvent }) => (
  <div tw="overflow-hidden grid grid-cols-6 rounded-lg">
    <div tw="col-span-6 md:col-span-4">
      <Image fluid={image.childImageSharp.fluid}></Image>
    </div>
    <div tw="col-span-6 md:col-span-2 bg-ui-03 p-4">
      <Text>
        <strong>Start: </strong>
        {moment(startDate).locale('nl').local().format(`DD MMMM, YYYY - kk:mm`)}
      </Text>
      <Text>
        <strong>Locatie: </strong>
        {location}
      </Text>
      <Text as="a" href={facebookEvent} tw="underline">
        Bekijk op facebook
      </Text>
    </div>
  </div>
)

export const EventPageTemplate = ({
  title,
  coverImage,
  startDate,
  location,
  facebookEvent,
  content
}) => (
  <div>
    <SEO title={title}></SEO>
    <ContentWrapper tw="mb-0 relative">
      <LargeEventCard
        image={coverImage}
        startDate={startDate}
        location={location}
        facebookEvent={facebookEvent}
      />
    </ContentWrapper>
    <div tw="relative before:(content absolute bg-ui-01 inset-x-0 -top-16 bottom-0 -z-10)">
      <ContentWrapper tw="max-w-screen-md py-24 mt-0">
        <H1>{title}</H1>
        <HTMLContent content={content} />
      </ContentWrapper>
    </div>
  </div>
)

const EventPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <Layout>
      <EventPageTemplate
        title={frontmatter.title}
        coverImage={frontmatter.coverImage}
        startDate={frontmatter.startDate}
        location={frontmatter.location}
        facebookEvent={frontmatter.facebook}
        content={html}
      />
    </Layout>
  )
}

export default EventPage

export const pageQuery = graphql`
  query EventByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        coverImage {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        startDate
        endDate
        facebook
        location
      }
    }
  }
`
